import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'
import Worksnav from '../components/Worksnav'

import SimpleSlider from '../components/Slider'


const Portrait = (props) => {
  const siteTitle = 'Onodera Ryo  | portrait'

  return(
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
      </Helmet>
      <div id="main">
        <div class="portrait">
          <Worksnav>
            Portrait
          </Worksnav>
          <SimpleSlider />
        </div>
      </div>
    </Layout>
  )
}

export default Portrait