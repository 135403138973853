import React from 'react'
import Slider from "react-slick"
import portrait01 from '../assets/images/portrait/2020/01.jpg'
import portrait02 from '../assets/images/portrait/2020/02.jpg'
import portrait03 from '../assets/images/portrait/2020/03.jpg'

import "slick-carousel/slick/slick.css";

const SimpleSlider = ({children}) => {
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000
    };
    const style = {
        margin: 0,
    };  
    return(
      <Slider {...settings}>
        <div class="portrait">
          <p class="photo_title">Kosuke Yokota</p>
          <img src={portrait01} alt="image"/>
        </div>
        <div class="portrait">
          <p class="photo_title">Kaito Yoshimura for ADVENTURE KING</p>
          <img src={portrait02} alt="image"/>
        </div>
        <div class="portrait">
          <p class="photo_title">Kyuichiro Nakayama</p>
          <img src={portrait03} alt="image"/>
        </div>
      </Slider>
    )
}

export default SimpleSlider